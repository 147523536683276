<template>
  <div class="SecurityPatrol">
    <van-tabs sticky animated v-model="jurisdictionId">
      <van-tab v-for="(item, index) in Treelist" :key="index">
        <template #title>
          <span @click="onClickTabs(item)" class="engineeringSpan">{{
            item.name
          }}</span>
        </template>
        <van-list
          v-model="loading"
          @load="getData"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
        >
          <van-swipe-cell v-for="(el, inx) in list" :key="inx">
            <div
              style="
                height: auto;
                min-height: auto;
                border-radius: 0;
              "
              @click="wxClick(el)"
            >
              <div
                class="myDiv flexcolumn"
                :style="
                  el.isovertime.includes('重大')
                    ? 'background-color:#ffe98d;box-shadow: none;'
                    : 'box-shadow: none'
                "
              >
                <div class="flexLR" style="width:100%">
                  <div class="flex" style="width:62%">
                    <van-image
                      round
                      width="30"
                      height="30"
                      :src="$store.state.baseUrl + el.user13"
                    />
                    <div class="flex" style="width:90%">
                      <h2 style="font-weight:bold">
                        {{ el.user04 }}
                      </h2>
                      <h2>
                        {{ el.famp10 }}
                      </h2>
                    </div>
                  </div>
                  <van-button
                    plain
                    round
                    type="info"
                    size="small"
                    @click="skip(el.fmap01)"
                    >查看</van-button
                  >
                </div>
                <div style="width:100%;text-align:center;font-weight:bold;">
                  <span v-if="el.famp13 == 1" style="color: red;">催！</span
                  >{{
                    el.fafd06
                      ? el.fafd06.length >= 25
                        ? el.fafd06.substr(0, 25) + "..."
                        : el.fafd06
                      : ""
                  }}
                </div>
                <div
                  style="width:100%;text-align:center;font-weight:bold;"
                  v-if="el.isovertime == '已逾期'"
                >
                  <span style="color: red;">{{ el.isovertime }}</span>
                </div>
                <div class="flexLR" style="width:100%">
                  <div class="flexLR" style="width:100%">
                    <div>阅读状态：{{ el.readnum }}人已读</div>
                    <div>
                      状态：<span style="color:#00a1ff">{{
                        famp11(el.famp11)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template #right>
              <div
                class="flexcolumn"
                style="height: 100%;width:100px"
                v-if="jurisdictionId == 0 && el.isaudit"
              >
                <div class="flexLR" style="height: 100%;width:100%">
                  <van-button
                    style="height: 100%;border-radius:0;width:100%"
                    type="info"
                    @click="BtnSubmitAudit(1, el.fmap01)"
                    size="mini"
                    >处理</van-button
                  >
                </div>
                <!-- <div class="flexLR" style="height: 100%;width:100%">
                  <van-button style="height: 100%;border-radius:0;width:100%" type="danger" size="mini"
                    @click="BtnSubmitAudit(2, el.fmap01)">驳回</van-button>
                </div> -->
              </div>
              <div
                class="flexcolumn"
                style="height: 100%;width:100px"
                v-if="el.famp08 == $store.state.uid"
              >
                <div
                  class="flexLR"
                  style="height: 100%;width:100%"
                  v-if="
                    el.famp13 != 1 &&
                      famp11(el.famp11) != '已完结' &&
                      famp11(el.famp11) != '已撤回'
                  "
                >
                  <van-button
                    style="height: 100%;border-radius:0;width:100%"
                    @click="PressFormApply(el.fmap01)"
                    type="info"
                    size="mini"
                    >{{ el.famp13 == 0 ? "催办" : "已催" }}</van-button
                  >
                </div>
                <div
                  class="flexLR"
                  v-if="el.famp11 != 4 && famp11(el.famp11) != '已完结'"
                  style="height: 100%;width:100%"
                >
                  <van-button
                    style="height: 100%;border-radius:0;width:100%"
                    type="warning"
                    @click="CancelFormApply(el.fmap01)"
                    size="mini"
                    >撤回</van-button
                  >
                </div>
                <div class="flexLR" style="height: 100%;width:100%">
                  <van-button
                    style="height: 100%;border-radius:0;width:100%"
                    type="danger"
                    size="mini"
                    @click="DelFormApply(el.fmap01)"
                    >删除</van-button
                  >
                </div>
              </div>
            </template>
          </van-swipe-cell>
        </van-list>
      </van-tab>
    </van-tabs>
    <div
      @click="$router.push('addSecurity')"
      style="position:fixed;z-index:9999;right:40px;bottom:20px"
    >
      <img
        round
        width="40"
        height="40"
        src="../../../assets/mobileImg/addBtn.png"
      />
    </div>
    <van-dialog
      v-model="rejectShow"
      title="驳回原因"
      @confirm="SubmitAudit(2, rejectId)"
      show-cancel-button
    >
      <van-field
        v-model="rejectContent"
        rows="1"
        autosize
        label="驳回内容"
        type="textarea"
        placeholder="请输入内容"
      />
    </van-dialog>
  </div>
</template>

<script>
import {
  AddFormApplyLink,
  GetFormApply,
  GetDataFileList,
  DelDataFile,
  PressFormApply,
  DelFormApply,
  CancelFormApply,
  SubmitAudit,
} from "@/api/index";
export default {
  name: "SecurityPatrol",
  components: {},
  data() {
    return {
      searchword: "",
      loading: false,
      finished: false,
      pageSize: 0,
      list: [],
      Treelist: [
        {
          name: "待处理",
          type: 4,
        },
        {
          name: "已处理",
          type: 5,
        },
        {
          name: "我发起的",
          type: 1,
        },
        {
          name: "抄送给我的",
          type: 3,
        },
      ],
      jurisdictionId: 0,
      rejectShow: false,
      rejectContent: "",
      rejectId: "",
    };
  },
  computed: {
    famp11() {
      return function(data) {
        switch (data) {
          case 1:
            return "待整改";
          case 2:
            return "已完结";
          case 3:
            return "已驳回";
          case 4:
            return "已撤回";
          case 5:
            return "待确认";
          default:
            return "未知状态";
        }
      };
    },
  },
  watch: {},
  methods: {
    BtnSubmitAudit(type, id) {
      if (type == 1) {
        this.$router.push({
          name: "auditInfo",
          query: { id },
        });
      } else {
        this.rejectId = id;
        this.rejectShow = true;
      }
    },
    SubmitAudit(type, id) {
      let fd = new FormData();
      fd.append("qid", this.$store.state.qid);
      fd.append("id", id);
      fd.append("type", type);
      fd.append("newtype", 2);
      type == 2 ? fd.append("content", this.rejectContent) : "";
      SubmitAudit(fd).then((res) => {
        if (res.Code) {
          this.$Toast(type == 1 ? "审核成功" : "驳回成功");
          this.getData(this.Treelist[this.jurisdictionId].type);
        }
      });
    },
    CancelFormApply(id) {
      CancelFormApply({ id, type: 2 }).then((res) => {
        if (res.Code) {
          this.$Toast("撤回成功");
          this.getData(this.Treelist[this.jurisdictionId].type);
        }
      });
    },
    DelFormApply(id) {
      DelFormApply({ id, qid: this.$store.state.qid }).then((res) => {
        if (res.Code) {
          this.$Toast("删除成功");
          this.getData(this.Treelist[this.jurisdictionId].type);
        }
      });
    },
    //催办
    PressFormApply(id) {
      PressFormApply({ id, type: 2 }).then((res) => {
        if (res.Code) {
          this.$Toast("催办成功");
          this.getData(this.Treelist[this.jurisdictionId].type);
        }
      });
    },
    skip(id) {
      AddFormApplyLink({
        fmar02: id,
        fmar03: this.$store.state.uid,
      }).then((res) => {
        this.$router.push({
          name: "SecurityInfo",
          path: "/home/menu/SecurityInfo",
          query: {
            id,
          },
        });
      });
    },
    async Delete(v) {
      let res = await DelDataFile({ fids: v.prfl01 });
      this.getData();
    },
    onClickTabs(v) {
      this.jurisdictionId = v.type;
      this.getData(v.type);
    },
    wxClick(item) {
      let data = {
        imgList: [],
        fileList: [],
      };
      let type =
        item.prfl04 != "" && item.prfl04
          ? item.prfl04.substr(item.prfl04.indexOf(".") + 1)
          : null;
      if (type == "png" || type == "jpg") {
        data.imgList.push(this.$store.state.baseUrl + item.prfl04);
      } else if (type == null) {
        return false;
      } else {
        data.fileList.push(this.$store.state.baseUrl + item.prfl04);
      }
      wx.miniProgram.navigateTo({
        url: `/pages/download/index?data=` + JSON.stringify(data),
      });
    },
    getData(type) {
      this.loading = true;
      this.finished = false;
      this.pageSize += 5;
      GetFormApply({
        params: {
          xid: this.$store.state.pid,
          uid: this.$store.state.uid,
          type: type ? type : this.Treelist[this.jurisdictionId].type,
          newtype: 2,
          searchword: this.searchword,
          pageIndex: 1,
          pageSize: this.pageSize,
          statetype: 0,
        },
      }).then((res) => {
        this.list = res.Data.list;
        if (res.Data.list.length >= res.Data.total) {
          this.finished = true;
        }
        this.loading = false;
      });
    },
    updateData() {
      this.list = [];
      this.Treelist = [];
      this.$emit("updateLoading", "工程资料");
      // this.getData();
    },
  },
  created() {
    if (this.$route.query.type) {
      this.jurisdictionId = this.$route.query.type;
    }
    this.getData(this.Treelist[this.jurisdictionId].type);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.SecurityPatrol {
  .myDiv {
    width: 100%;
    padding: 10px 40px;
    border: 1px solid #dddddd;
  }

  width: 100%;
  // height: 100vh;
  margin: 0 auto;

  .van-tabs {
    height: auto;
  }

  /deep/ .van-tabs__line {
    background-color: rgb(255, 255, 255);
  }

  /deep/ .van-tabs__nav {
    background-color: rgba(255, 255, 255, 0);
  }

  .engineeringSpan {
    color: white;
  }

  /deep/ .van-tab__text {
    padding: 0 10px;
  }

  /deep/ .van-tab--active .van-tab__text .engineeringSpan {
    position: relative;
    z-index: 1;
    min-width: 62px;
    text-align: center;
    display: block;
    color: rgb(0, 119, 255);

    &::after {
      content: "";
      position: absolute;
      left: -5px;
      z-index: -1;
      top: 0;
      background-color: rgb(255, 255, 255);
      width: 100%;
      padding: 0 5px;
      border-radius: 10px;
      height: 100%;
    }
  }

  /deep/ .van-tabs__content {
    height: 93%;
    overflow: hidden;
    overflow-y: auto;
  }
}

.headTree {
  width: 100%;
  height: 40px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #0095ff;
}
</style>
